<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card card-body printableArea"><h3>
        <b>Filter Surat</b>
        </h3>
        <hr>
        <div class="row">
          <div class="col-md-12">
            <form method="post" @submit.prevent="filterData">
              <div class="form-body">

                <!-- <div class="row">
                  <div class="col-md-8">
                    <div class="form-group row">
                      <label :class="['control-label', {'text-right': !isMobile}, 'col-md-3']">
                        Periode Surat
                      </label>
                      <div class="col-md-3">
                        <input
                        type="date"
                        class="form-control"
                        data-date=""
                        data-date-format="YYYYY-MM-DD"
                        v-model="start_date"
                        />
                      </div>
                      <label :class="['control-label', {'text-center': !isMobile}, 'col-md-3']"> s/d </label>
                      <div class="col-md-3">
                        <input
                          type="date"
                          class="form-control"
                          data-date=""
                          data-date-format="YYYYY-MM-DD"
                          v-model="end_date"
                        />
                      </div>
                    </div>
                  </div>
                </div> -->

                <!-- <div class="row">
                  <div class="col-md-8">
                    <div class="form-group row">
                      <label :class="['control-label', {'text-right': !isMobile}, 'col-md-3']">
                        Keterangan
                      </label>
                      <div class="col-md-8">
                        <Input v-model="keterangan" type="text" name="keterangan" id="keterangan" class="form-control" />
                      </div>
                    </div>
                  </div>
                </div> -->

                <!-- <div class="row">
                  <div class="col-md-8">
                    <div class="form-group row">
                      <label :class="['control-label', {'text-right': !isMobile}, 'col-md-3']">
                        Klasifikasi
                      </label>
                      <div class="col-md-8">
                        <treeselect 
                        v-model="klasifikasi" 
                        :options="indukklasifikasi" 
                        />
                      </div>
                    </div>
                  </div>
                </div> -->

                <div class="row">
                  <div class="col-md-8">
                    <div class="form-group row">
                      <label :class="['control-label', {'text-right': !isMobile}, 'col-md-3']">
                        Status
                      </label>
                      <div class="col-md-8">
                        <treeselect 
                          v-model="status"
                          :options="optionsstatus"
                        />
                      </div>
                    </div>
                  </div>
                </div>                

              </div>
              <hr>
              <div class="form-actions">
                <div class="row">
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-offset-3 col-md-9">
                        <button type="submit" class="btn btn-success" title="Search">Submit</button>&nbsp;
                        <button type="button" @click="resetInput()" class="btn btn-inverse" title="Reset">Reset</button>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6"></div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-12">
      <div class="row justify-content-md-center" v-if="!state.loaded">
        <div class="col col-lg-2"></div>
        <div class="col-md-auto mt-20">
          <rotate-square5></rotate-square5>
        </div>
        <div class="col col-lg-2"></div>
      </div>
      <div class="card card-body printableArea" v-else>
          <h3>
          <b>&nbsp;</b>
          <div class="pull-right">
            <button @click="exportExcelData('daftarsuratlist')" class="btn btn-success">Export Excel</button>&nbsp;
            <button type="button" @click="showModal({type:'cancel'})" class="btn btn-inverse" title="Kembali">Kembali</button>&nbsp;
            <!-- <button
              @click="onDownload"
              class="btn btn-sm btn-success"
              title="Print"
            >
              <i class="mdi mdi-printer"></i> Print
            </button>&nbsp; -->
            <!-- <downloadexcel
              class="btn btn-sm btn-success"
              :fetch="exportExcel"
              :fields="json_fields"
              worksheet="Data Detail Berkas"
              name="Data_Detail_Berkas.xls"
              title="Data Detail Berkas"
            >
                Export Excel
            </downloadexcel> -->
          </div>
        </h3>
        <hr>
        <div class="row">
          <div class="col-md-12">
            <Table
              :items="items"
              :filter="filters"
              :header="headers"
              :onAction="pagination"
              :onDetail="doDetail"
              @onDelete="doDelete"
              :overideSearch="search"
							@onSearch="onSearch"
              :forbidEdit="forbidUpdate"
              :forbidDetail="forbidRead"
              :forbidPemberkasan="forbidPemberkasan"
              :forbidDelete="true"
            />
          </div>
        </div>
      </div>

      <hr>
      <div class="card card-body printableArea">
        <div class="col-md-12">
          <h4>Petunjuk :</h4>
        </div>
        <div class="col-md-12">
          <table>
            <tr>
              <td><span class="btn-sm btn-success mr-1"><i class="mdi mdi-magnify"></i></span></td>
              <td style="font-size: 13px;">Untuk melihat detail surat</td>
            </tr>
            <tr>
              <td colspan="2">&nbsp;</td>
            </tr>
          </table>
        </div>
      </div>

    </div>
    <ModalDefault v-show="isVisible" :title="modalTitle" :content="modalContent" :action="ActionChangeable" :confirmation="isConfirmation" @close="onClose"/>
  </div>
</template>
<script>
import Table from "@/components/table/table";
import moment from 'moment';
import { RotateSquare5 } from "vue-loading-spinner";
import Multiselect from "vue-multiselect";
import downloadexcel from "vue-json-excel";
import axios from 'axios';

var pdfMake = require("pdfmake/build/pdfmake");
var pdfFonts = require("pdfmake/build/vfs_fonts");
pdfMake.vfs = pdfFonts.pdfMake.vfs;
var htmlToPdfmake = require("html-to-pdfmake");

export default {
  components: {
    RotateSquare5,
    Table,
    Multiselect,
    downloadexcel
  },
  data() {
    let last = localStorage.getItem('last_request');
    last = last != null ?last.split('?'):',?,'.split('?');
    let params = {};
    if(last[0].includes('folderdipermanenkan')){
        params = JSON.parse(last[1]);
    }
    if(params.approval!=undefined){
        params = {}
    }
    return {
      search: params.search || '',
      start_date : params.start_date || moment().startOf('year').format('YYYY-MM-DD'),
      end_date : params.end_date || moment().endOf('year').format('YYYY-MM-DD'),
      tanggal : new Date().getDate()+'-'+(new Date().getMonth()+1)+'-'+new Date().getFullYear(),
      keterangan: params.keterangan || null,
      klasifikasi: params.klasifikasi || null,
      status: params.status || null,
      nomor : 1,
      // indukklasifikasi: this.$store.state.pemberkasan.items.treeklasifikasi,
      indukklasifikasi: [],
      options: [],
      optionsketerangan: [],
      optionsstatus: [],
      payload: {
        klasifikasi: null,
        keterangan: null,
        status: null
      },
      // tanggal : today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate(),
      headers: {
        asal_berkas: {
          label: "Asal Berkas",
          sortable: true,
        },
        naskah_dinas: {
          label: "Naskah Dinas",
          sortable: true,
        },
        subject: {
          label: "Perihal",
          sortable: false
        },
        updated_at: {
          label: "Tanggal",
          sortable: true,
        },
        nomorsurat: {
          label: "Nomor Surat",
          sortable: false,
        },
        sender: {
          label: "Pengirim/Kepada",
          sortable: false,
        },
        status: {
          label: "Status",
          sortable: false,
        },
        
      },
      json_fields: {
        'Nomor Urut':'urutan',
        'Asal Berkas':'asal_berkas',
        'Naskah Dinas':'naskah_dinas',
        'Perihal':'subject',
        'Tanggal':'updated_at',
        'Nomor Surat':'nomorsurat',
        'Pengirim/Kepada':'sender',
        'Status':'status'
        // 'Nomor Urut':'urutan',
        // 'Tanggal Penerimaan Dokumen':'tanggal_diterima',
        // 'Nomor Dokumen':'nomor_surat',
        // 'Tanggal Dokumen':'tanggal_surat',
        // 'Sifat Surat':'sifat_surat',
        // 'Perihal':'perihal',
        // 'Dari':'asal_surat',
        // // 'Kepada':'agenda_number',
        // 'Jenis Dokumen':'jenis_dokumen',
        // 'Redaksi':'redaksi',
        // 'Keterangan Surat':'keterangan_surat',
        // 'Jumlah Halaman':'halaman',
        // // 'Tujuan Disposisi':'disposisi',
        // // 'Catatan Disposisi':'catdispo'
      },
      isModalVisible: false,
      isVisible: false,
      modalTitle: '',
      modalContent: '',
      isConfirmation: false,
      ActionChangeable: null,
    };
  },
  computed: {
    items() {
      return this.$store.state.folderdipermanenkan.items.data;
    },
    prints() {
      return this.$store.state.folderdipermanenkan.items ? this.$store.state.folderdipermanenkan.items.prints : [];
    },
    filters() {
      return this.$store.state.folderdipermanenkan.items ? this.$store.state.folderdipermanenkan.items.filters: {};
    },
    isMobile() {
      return this.$store.state.isMobile;
    },
    state() {
      return this.$store.state.folderdipermanenkan;
    },
    tanggal_diterima(){
      return formatDate(this.$store.state.folderdipermanenkan.tanggal_diterima);
    },
    urutan(){
      return this.$store.state.folderdipermanenkan.urutan;
    },
    forbidUpdate(){
      if(this.$store.state.profile.permissions.laporan_docin){
        return !this.$store.state.profile.permissions.laporan_docin.update;
      }
      return false;
    },
    forbidRead(){
      if(this.$store.state.profile.permissions.laporan_docin){
        return !this.$store.state.profile.permissions.laporan_docin.read;
      }
      return false;
    },
    forbidPemberkasan(){
      if(this.$store.state.profile.permissions.pemberkasan_berkas){
        return this.$store.state.profile.permissions.pemberkasan_berkas.create;
      }
      return false;
    },
    forbidDel(){
      if(this.$store.state.profile.permissions.laporan_docin){
        return !this.$store.state.profile.permissions.laporan_docin.delete;
      }
      return false;
    }
  },
  mounted() {
    var val = {
      start_date: this.start_date,
      end_date: this.end_date,
      keterangan: this.keterangan,
      klasifikasi: this.klasifikasi,
      status: this.status
    }
    if (typeof(params) == "object" && params.constructor.name === "Object") {
      val = Object.assign(params, val);
    }
    const state = {
      loaded: false,
      items: [],
      search: this.search,
      prints: []
    };
    this.detailLoad();
    this.treeKlasifikasi();
    this.treeStatus();
  },
  methods: {
    exportExcelData(val){
      const link = document.createElement('a')
      let unit_id = JSON.parse(localStorage.getItem('user')).unit_id
      link.href = `${this.baseUrl}/${val}/${this.$route.params.id}/export-excel?start_date=${this.start_date}&end_date=${this.end_date}&unit_id=${unit_id}`
      link.target = "_blank";
      link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
    },
    treeKlasifikasi(){
      axios.get(`/clasificationdoc/get_list_klasifikasi`).then(res=>{
        this.options = res.data.data;
      })
    },
    treeStatus(){
      this.optionsstatus = [{"id": 1,"label": "Active"},{"id": 0,"label": "Inactive"}];
    },
    onSearch(val){
      this.search = val
    },
    get(val) {
      this.$store.dispatch("folderdipermanenkan/getfolderdipermanenkanListById2", val);
    },
    getNow: function() {
      const today = new Date();
      const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      const dateTime = date +' '+ time;
      this.timestamp = dateTime;
    },
    resetInput() {
      this.start_date = null;
      this.end_date = null;
      this.klasifikasi = null;
      this.keterangan = null;
      this.status = null;
      var val = {
        status: this.status
      }
      this.get(val);
    },
    pagination(params) {
      this.filterData(null, params);
    },
    filterData: function (event, params) {
      var val = {
        // start_date: this.start_date,
        // end_date: this.end_date,
        id: this.$route.params.id,
        status: this.status
      }
      if(typeof(params) == "object" && params.constructor.name === "Object") {
        val = Object.assign(params, val);
      }
      this.get(val);
    },
    doDetail(val) {
      // this.$store.dispatch("folderdipermanenkan/onDetailSurat", val);
      const idsurat = val.id_surat;
      let route = this.$router.resolve({path: '/folderdipermanenkan/detailsurat'});
      let route1 = `${route.href}/${idsurat}`;
      window.open(route1, '_blank');
    },
    // doMoveBerkas(val) {
    //   const data = {
    //     id_surat: val.id_surat,
    //     unit: this.state.items.idunit
    //   };
    //   // this.$store.dispatch("folderdipermanenkan/onMoveBerkas", val);
    //   this.$store.dispatch("folderdipermanenkan/onMoveBerkas", data);
    // },
    doEdit(val) {
      this.$store.dispatch("folderdipermanenkan/onEdit", val);
    },
    doDelete(val) {
      this.$store.dispatch("folderdipermanenkan/submitDelete", val);
    },
    // detailLoad() {
    //   const state = {
    //     loaded: false
    //   };
    //   const user = localStorage.getItem("user");
    //   this.$store.commit("folderdipermanenkan/STATE", state);
    //   this.$store.dispatch("folderdipermanenkan/getfolderdipermanenkanListById", this.$route.params);
    // },
    detailLoad(val) {
      const state = {
        loaded: false
      };
      var val = {
        id: this.$route.params.id,
        status: this.status
      }
      const user = localStorage.getItem("user");
      this.$store.commit("folderdipermanenkan/STATE", state);
      this.$store.dispatch("folderdipermanenkan/getfolderdipermanenkanListById2", val);
    },
    onClose(){
      this.isVisible = false;
    },
    showModal(option){
      this.isVisible = true;
      if(option.type == 'cancel'){
        this.modalTitle = "Kembali?";
        this.modalContent = "<p>Apakah anda yakin ingin kembali?</p>";
        this.isConfirmation = true;
        this.ActionChangeable = this.goBack;
      }
    },
    goBack() {
      this.$router.push("/folderdipermanenkan");
    },

    async exportExcel() {

      if (this.$store.state.folderdipermanenkan.items.filters.start_date == null && this.$store.state.folderdipermanenkan.items.filters.end_date === null) {
          const response = await axios.get('folderdipermanenkan/'+this.$route.params.id+'?search=&sortable=asc');
          return response.data.prints;
      } else {
          const response = await axios.get('folderdipermanenkan/'+this.$route.params.id+'?start_date='+this.start_date+'&end_date='+this.end_date);
          return response.data.prints;
      }

    }

  },
   watch: {
    $route: function(search) {
      this.detailLoad();
    }

  }
};
</script>
